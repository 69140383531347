const errorCode = {
    NoError:                                0,
    ServerError:                            1001,
    DataNullError:                          1002,
    SQLError:                               1003,
    DataError:                              1004,
    PermissionDeniedError:                  1005,
    AuthError:                              1006,
    AuthInValidError:                       1007,
    isEmptyError:                           1008,
  
    FILE_ImageNoFoundError:                 2001,
  
    USER_LoginTokenTimeoutError:            3001,
    USER_PhoneCodeCoolDownTimeError:        3002,   //验证码冷却时间未到
    USER_PhoneCodeBadError:                 3003,   //手机号验证码错误
    USER_PhoneWrongError:                   3004,
    USER_AccountOrPasswordWrongError:       3005,
    User_UserIsExistsError:                 3006,
    USER_PhoneIsExistsError:                3007,
    USER_AccountIsExistsError:              3008,
    USER_WeChatIdIsExistsError:             3009,
    USER_UserNoPasswordError:               3010,
    USER_UserNeedToRegistryError:           3011,   //需要注册
    USER_UserNoFoundError:                  3012,
    USER_UserIsProcessStateError:           3013,
    
    STUDENT_StudentIsExistsError:           4001,
    
    MATCHES_GetQRCodeError:                 5001,
    MATCHES_ContestantDataError:            5002,
    MATCHES_MatchesNoFoundError:            5003,
    MATCHES_MatchesDataError:               5004,
    MATCHES_ContestantIsExistsError:        5005,
    MATCHES_MatchesNoEnrollStateError:      5006,
    MATCHES_StudentExamResultNoFoundError:  5007,
    MATCHES_NoContestantError:              5008,
    Matches_MatchesIsNotFinishStateError:   5009,
    Matches_MatchesIsFinishStateError:      5010,
  
    MATCHES_RATIO_RatioNoFoundError:        6001,
  
    EVENT_REGISTRATION_RegistrationNoFoundError:              7001,
    EVENT_REGISTRATION_RegistrationContestantDataError:       7002,
    EVENT_REGISTRATION_RegistrationContestantIsExistsError:   7003,
    EVENT_REGISTRATION_RegistrationStateError:                7004,
    EVENT_REGISTRATION_RegistrationNoRegistrationStateError:  7005,
  
    GYMS_GymsNeedToRegistryError:     8001,
    GYMS_GymsIsProcessStateError:     8002,
    GYMS_CuratorNoFoundError:         8003,
    GYMS_GymsNameIsExistsError:       8004,
    GYMS_UserChiefGymsOnlyByOneError: 8005,
  
    GYMS_GymsClassNoFoundError:       9001,
    GYMS_GymsClassNameIsExistsError:  9002,
  
    GYMS_GymsStudentNoFoundError:           10001,
    GYMS_GymsStudentStudentIsExistsError:   10002,
  
    GYMS_GymsTeacherNoFoundError:           11001,
  
    GYMS_GymsSignInLogIsEmptyError:         12001,
  
    AUTH_AuthNoFoundError:              13001,
  }
  
  const errorCodeByText = {
    "-1"  :     "连接服务器出错！",
    "0"   :     "无错误",
  
    "1001":     "服务器错误！",
    "1002":     "数据有空！",
    "1003":     "数据格式有误！",
    "1004":     "数据错误！",
    "1005":     "权限不足！",
    "1006":     "权限不足！",
    "1007":     "授权失效！",
    "1008":     "内容为空！",
  
    "2001":     "照片不存在！",
  
    "3001":     "登录过期！",
    "3002":     "请一分钟后再发送验证码！",
    "3003":     "手机号验证码错误！",
    "3004":     "手机号错误！",
    "3005":     "账号或密码错误！",
    "3006":     "该用户已存在！",
    "3007":     "该手机号已被注册！",
    "3008":     "该账号已被注册！",
    "3009":     "该微信已被注册！",
    "3010":     "该账号仅能验证码登录！",
    "3011":     "该用户需要使用验证码登录！",
    "3012":     "该用户不存在",
    "3013":     "账户审核中！",
  
    "4001":     "该学生已存在！",
  
    "5001":     "获取微信二维码错误！",
    "5002":     "考级选手数据格式错误！",
    "5003":     "该考级信息为空！",
    "5004":     "该考级数据异常",
    "5005":     "该选手已存在！",
    "5006":     "当前考级非报名状态！",
    "5007":     "该学生考级结果查询为空！",
    "5008":     "该考级没有考级人员",
    "5009":     "该考级不在结束状态",
    "5010":     "该考级已结束，禁止任何修改操作！",
  
    "6001":     "该考核模板不存在！",
  
    "7001":     "该活动不存在！",
    "7002":     "人员报名数据错误！",
    "7003":     "该人员已报名",
    "7004":     "状态数据错误！",
    "7005":     "该活动非报名状态！",
    
    "8001":     "该账号需要添加机构信息才可以使用！",
    "8002":     "该账号审核中！",
    "8003":     "该机构不存在！",
    "8004":     "该机构名称已存在！",
    "8005":     "一个用户只能创建一个主馆！",
  
    "9001":     "机构班级不存在",
    "9002":     "机构名称重复！",
  
    "10001":    "机构学生不存在！",
    "10002":    "机构学生已经存在！",
  
    "11001":    "该机构教练不存在！",
  
    "12001":    "签到信息为空！",
  
    "13001":    "授权不存在！",

    "14001":    "该班级无此人员！"
  }
  
  function getErrorCodeByText(data = "-1") {
    return errorCodeByText[data];
  }
  
  module.exports = {
    errorCode,
    getErrorCodeByText,
  }