
import SystemPara from "@/data/SystemPara";
import http from "@/utils/http";
import errorCode from "./errorCode";

const rootUrl = "/FaceRecognition/";

function faceBase64SearchToStudentIdByOneToMany(gymsId, classId, fileByBase64, success, fail) {
  http.postServer(
    `${rootUrl}faceBase64SearchToStudentIdByOneToMany`,
    {
        userToken: SystemPara.getUserToken(),
        gymsId: gymsId,
        classId: classId,
        fileByBase64: fileByBase64,
    },
    (res) => {
        var result = res.data;
        if(result.code != errorCode.errorCode.NoError) {
            fail(errorCode.getErrorCodeByText(result.code))
            return
        }
        success(result.data);
    },
    (res) => {
      fail(errorCode.getErrorCodeByText())
    }
  );
}


export default {
  faceBase64SearchToStudentIdByOneToMany: faceBase64SearchToStudentIdByOneToMany,
};