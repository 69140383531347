import { createStore } from 'vuex'

export default createStore({
  state: {
    showHeader: true,
  },
  mutations: {
    changeShowHeader(state, isShow) {
        state.showHeader = isShow;
    }
  },
  actions: {
  },
  modules: {
  }
})