
import http from "@/utils/http"
import SystemPara from "@/data/SystemPara"
import errorCode from "./errorCode"

var rootUrl = "/GymsClassSchedule/";

function appendGymsClassScheduleSignInBeforeStudent(gymsId, classId, startTime, endTime, studentId, status, success, fail) {
    http.postServer(
        rootUrl + "appendGymsClassScheduleSignInBeforeStudent",
        {
            userToken: SystemPara.getUserToken(),
            gymsId: gymsId,
            classId: classId,
            startTime: startTime,
            endTime: endTime,
            studentId: studentId,
            status: status,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success()
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

function selectGymsClassScheduleByGymsIdAndClassId(gymsId, classId, success, fail) {
    http.postServer(
        rootUrl + "selectGymsClassScheduleByGymsIdAndClassId",
        {
            userToken: SystemPara.getUserToken(),
            gymsId: gymsId,
            classId: classId,
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success(result.data)
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

export default {
    appendGymsClassScheduleSignInBeforeStudent: appendGymsClassScheduleSignInBeforeStudent,
    selectGymsClassScheduleByGymsIdAndClassId: selectGymsClassScheduleByGymsIdAndClassId,
};