
import http from "@/utils/http"
import SystemPara from "@/data/SystemPara"
import errorCode from "./errorCode"

var rootUrl = "/Gyms/"

function selectGymsAllAndClassAllByUserId(success, fail) {
    http.postServer(
        rootUrl + "selectGymsAllAndClassAllByUserId",
        {
            userToken: SystemPara.getUserToken(),
        },
        (res) => {
            var result = res.data;
            if(result.code != errorCode.errorCode.NoError) {
                fail(errorCode.getErrorCodeByText(result.code))
                return
            }
            success(result.data)
        },
        (res) => {
            fail(errorCode.getErrorCodeByText())
            console.log(res);
        }
    )
}

export default {
    selectGymsAllAndClassAllByUserId: selectGymsAllAndClassAllByUserId,
};