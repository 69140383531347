import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

import main from "@/view/main/main"

import matches from '@/view/matches/matches'
import matchesScreen from '@/view/matches/screen'
import matchesSelect from '@/view/matches/matches'

import gymsClass_signInByFaceRecognition from '@/view/gyms/gyms_class/signInByFaceRecognition'

import matchesControlCreate from '@/view/matchesControl/create'
import matchesControlList from '@/view/matchesControl/list'
import matchesControlShow from '@/view/matchesControl/show'
import matchesControlContestantIndex from '@/view/matchesControl/contestantIndex'

import matchesRatioCreate from '@/view/matchesRatio/create'
import matchesRatioList from '@/view/matchesRatio/list'
import matchesRatioShow from '@/view/matchesRatio/show'

import userLogin from "@/view/user/userLogin"
import userRegistry from "@/view/user/userRegistry"

import wechatLoginCheck from '@/view/auth/wechatLoginCheck'

const routes = [
  {
    path: '/',
    name: 'main',
    component: main,
  },
  {
    path: "/auth",
    children: [
      {
        path: "wechatLoginCheck",
        component: wechatLoginCheck,
      }
    ]
  },
  {
    path: '/gyms',
    children: [
      {
        path: "class",
        children: [
          {
            path: "signInByFaceRecognition",
            component: gymsClass_signInByFaceRecognition,
          }
        ],
      },
    ]
  },
  {
    path: '/matches',
    children: [
      {
        path: "matches",
        component: matches,
      },
      {
        path: "screen",
        component: matchesScreen,
      },
      {
        path: 'select',
        component: matchesSelect,
      },
    ]
  },
  {
    path: '/matchesControl',
    children: [
      {
        path: "create",
        component: matchesControlCreate,
      },
      {
        path: "list",
        component: matchesControlList,
      },
      {
        path: 'show:matchesId',
        name: 'matchesControlShow',
        component: matchesControlShow,
      },
      {
        path: 'contestantIndex',
        component: matchesControlContestantIndex,
      }
    ]
  },
  {
    path: '/matchesRatio',
    children: [
      {
        path: "create",
        component: matchesRatioCreate,
      },
      {
        path: "list",
        component: matchesRatioList,
      },
      {
        path: 'show:ratioId?',
        name: 'matchesRatioShow',
        component: matchesRatioShow,
      },
    ]
  },
  {
    path: '/user',
    children: [
      {
        path: "userLogin",
        component: userLogin,
      },
      {
        path: 'userRegistry',
        component: userRegistry,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
