const gymsClassStudentSignInClassStatus = {
    Normal: 0,
    PersonalLeave: 1,
    Absence: 2,
    Late: 3,
    LeaveEarly: 4,
}

export default {
    gymsClassStudentSignInClassStatus: gymsClassStudentSignInClassStatus,
}