
import localData from "@/utils/localData"

function getUserToken() {
    return localData.getUserToken() || undefined;
}

function setUserToken(userToken) {
    localData.setUserToken(userToken);
}



//develop
// const serverUrl =  "/xiheServer/";
// const baseURL = "/xiheServer/";



//release
const serverUrl = "https://edu.xihetiyu.com/API/";
const baseURL = "/API/";



export default {
    getUserToken,
    setUserToken,

    serverUrl,
    baseURL,
}