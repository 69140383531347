<template>
    <div class="parentView" v-if="matchesRatio != undefined">
        <div class="title">
            <h1>考核项模板（{{ matchesRatio.name }}）</h1>
        </div>

        <div v-for="(item) in matchesRatio.ratioList" :key="item">
            <el-table :data="item.ratioSubItem" stripe style="width: 100%;" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}">
                <el-table-column prop="ratioTitle" :label="'考核项名称（' + item.ratioTitle + '）'" />
                <el-table-column prop="ratioScore" :label="'考核项分数总和（' + item.ratioScore + '）'" />
            </el-table>
        </div>
    </div>
</template>

<script>
    import interaction from '@/utils/interaction';
    import matches_ratio from '@/models/matches_ratio';

    export default {
        name: 'show',
        props: {
            msg: String
        },
        data() {
            return {
                ratioId: undefined,
                matchesRatio: undefined,
            }
        },
        methods: {
            initData() {
                matches_ratio.selectMatchesRatioByRatioId(
                    this.ratioId,
                    (res) => {
                        this.matchesRatio = res;
                    },
                    (res) => {
                        this.backView(res);
                    }
                );
            },
            backView(errorTitle) {
                this.$router.push('/matchesRatio/list');
                interaction.showErrorMessage(errorTitle);
            },
        },
        mounted() {
            this.ratioId = this.$route.params.ratioId || undefined;
            if(this.ratioId == undefined) {
                this.backView("请选择考核项模板")
                return;
            }

            this.initData();
        }
    }
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>
</style>