
const GlobalSData = {
    faceCascade: null
};

function init(success) {
    initOpencv()
    .then(() => {
        return initCascadeClassifier();
    })
    .then(() => {
        success();
    })
    .catch((err) => {
        alert(err);
    })
}

function initOpencv() {
    return new Promise((resolve, reject) => {
        if (!window.WebAssembly) {
            reject("浏览器不支持！");
            return
        }
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = 'async'
        script.src = `/static/js/opencv.js`
        document.body.appendChild(script)
        script.onload = () => {
            cv.onRuntimeInitialized = () => {
                resolve();
            };
        }
    })
}

function initCascadeClassifier() {
    return new Promise((resolve, reject) => {
        fetch('/static/xml/haarcascade_frontalface_default.xml')
        .then(response => response.arrayBuffer())
        .then(buffer => {
            cv.FS_createDataFile('/', 'haarcascade_frontalface_default.xml', new Uint8Array(buffer), true, false, false);

            const faceCascade = new cv.CascadeClassifier();
            const result = faceCascade.load('/haarcascade_frontalface_default.xml');
            if(!result) {
                reject("faceCascade no created");
                return;
            }

            GlobalSData.faceCascade = faceCascade;
            resolve();
        })
        .catch(err => {
            reject("Error loading the XML file:", err);
        });
    })
}

export default {
    GlobalSData: GlobalSData,
    init: init,
}