<template>

    <div class="background" v-if="screenMode == 1">
        <div class="leftBackground" style="width: 80%; height: 100%;">
            <div class="faceRecognitionBackground" style="width: 100%; height: 100%;">
                <video id="video" ref="video" class="fixAll imageMode" autoplay></video>
                <canvas hidden id="canvasOutput" class="fixAll"></canvas>
            </div>
        </div>
        <div class="rightBackground" style="width: 20%; height: 100%;">
            <div style="width: 100%; height: 80%">
                <div>当前时间：<br />{{ nowTimeText }}</div>

                <div class="marginBottom"></div>

                <div class="marginBottom">当前状态：{{ nowStateText }}</div>

                <div class="marginBottom"></div>

                <div class="marginBottom">今日课表：</div>
                <li v-for="item in classScheduleList" :key="item.key" class="marginBottom">
                    {{ item.timeText }}
                </li>
            </div>

            <div class="identifiedBackground" style="width: 100%; height: 20%">
                <el-image class="identifiedImageItem" :src="identifiedUrl" fit="cover">
                    <template #error><div class="image-slot"></div></template>
                </el-image>
                <div class="identifiedTextBackground">
                    <div>
                        {{ identifiedName }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="background" v-if="screenMode == 2">
        <div class="leftBackground" style="width: 60%; height: 100%">
            <div class="faceRecognitionBackground" style="width: 100%; height: 70%;">
                <video id="video" ref="video" class="fixAll imageMode" autoplay></video>
                <canvas hidden id="canvasOutput" class="fixAll"></canvas>
            </div>
            <div style="width: 100%; height: 3%"></div>
            <div class="identifiedBackground" style="width: 100%; height: 30%;">
                <el-image class="identifiedImageItem" :src="identifiedUrl" fit="cover">
                    <template #error><div class="image-slot"></div></template>
                </el-image>
                <div class="identifiedTextBackground">
                    <div>
                        {{ identifiedName }}
                    </div>
                </div>
            </div>
        </div>
        <div class="rightBackground" style="width: 40%; height: 100%">
            <div>当前时间：<br />{{ nowTimeText }}</div>

            <div class="marginBottom"></div>

            <div class="marginBottom">当前状态：{{ nowStateText }}</div>

            <div class="marginBottom"></div>

            <div class="marginBottom">今日课表：</div>
            <li v-for="item in classScheduleList" :key="item.key" class="marginBottom">
                {{ item.timeText }}
            </li>
        </div>
    </div>

    <div class="background" v-if="screenMode == 3">
        <div class="leftBackground" style="width: 100%; height: 100%">
            <div class="faceRecognitionBackground" style="width: 100%; height: 35%;">
                <video id="video" ref="video" class="fixAll imageMode" autoplay></video>
                <canvas hidden id="canvasOutput" class="fixAll"></canvas>
            </div>

            <div class="marginBottom"></div>

            <div class="identifiedBackground" style="width: 100%; height: 15%">
                <el-image class="identifiedImageItem" :src="identifiedUrl" fit="cover">
                    <template #error><div class="image-slot"></div></template>
                </el-image>
                <div class="identifiedTextBackground">
                    <div>
                        {{ identifiedName }}
                    </div>
                </div>
            </div>

            <div class="marginBottom"></div>

            <div style="width: 100%; height: 50%">
                <div>当前时间：<br />{{ nowTimeText }}</div>

                <div class="marginBottom"></div>

                <div class="marginBottom">当前状态：{{ nowStateText }}</div>

                <div class="marginBottom"></div>

                <div class="marginBottom">今日课表：</div>
                <li v-for="item in classScheduleList" :key="item.key" class="marginBottom">
                    {{ item.timeText }}
                </li>
            </div>
        </div>
    </div>

    <el-dialog v-model="selectGymsAndClassShow" title="机构/班级选择">
        <el-tree-select :model-value="selectorShowText" :data="gymsSelectorList" :render-after-expand="false" style="width: 240px" @change="changeSelectorData" />
        <template #footer>
        <span class="dialog-footer">
            <el-button type="primary" @click="isChangeSelectorData">保存信息</el-button>
        </span>
        </template>
    </el-dialog>
</template>

<script>
import faceDetection from "@/utils/faceDetection"
import faceRecognition from "@/models/faceRecognition"
import user from "@/models/user"
import gyms from "@/models/gyms"
import gymsData from "@/data/gyms"
import gyms_class from "@/models/gyms_class"
import gyms_classSchedule from "@/models/gyms_classSchedule"
import fileImage from "@/models/fileImage"

import { computed } from 'vue';
import { useStore } from 'vuex';
import dataJudgment from '@/utils/dataJudgment'
import interaction from '@/utils/interaction'

export default {
    name: 'gymsClass_signInByFaceRecognition',
    props: {
        msg: String
    },
    setup() {
        const store = useStore();
        const changeShowHeader = (isShow) => {
            store.commit('changeShowHeader', isShow); // 提交 mutation 修改 state
        };
    
        return {
            changeShowHeader
        }
    },
    data () {
        return {
            screenMode: 1,

            height: undefined,

            nowTimeTimer: undefined,
            nowTimeText: "",
            nowStateTimer: undefined,
            nowState: 0,
            nowStateText: "",

            selectGymsAndClassShow: false,

            gyms: [],
            gymsClass: [],
            gymsSelectorList: [],
            selectorShowText: "",

            gymsId: 0,
            classId: 0,

            classScheduleList: [],
            nowClassScheduleItem: {},

            identifiedTimeoutObject: undefined,
            identifiedUrl: "",
            identifiedName: "",
        }
    },
    methods: {
        resizeScreen() {
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            let screenMode = this.screenMode;

            this.height = height + "px";

            if(width / height >= 1.2) {
                this.screenMode = 1;
            }
            else if(width / height >= 0.6) {
                this.screenMode = 2;
            }
            else {
                this.screenMode = 3;
            }

            if(screenMode != this.screenMode) {
                this.closeCamera();
                this.openCamera();
            }
        },

        getNowStateText() {
            switch(this.nowState) {
                case 1:
                    return "等待上课";
                case 2:
                    return "签到中";
                case 3:
                    return "上课中";
                default:
                    return "无状态";
            }
        },

        userSignInStateCheck() {
            if(this.nowState == 2 || this.nowState == 3) {
                return true;
            }
            return false;
        },

        refreshClassScheduleAndNowState() {
            const nowTime = new Date();

            let nowClassScheduleItem = undefined;
            for(let item of this.classScheduleList) {
                if(nowTime.getTime() > item.endTime.getTime()) {
                    continue;
                }

                nowClassScheduleItem = item;
                break;
            }

            if(nowClassScheduleItem == undefined) {
                this.nowState = 0;
                this.nowStateText = this.getNowStateText();
                return;
            }

            this.nowClassScheduleItem = nowClassScheduleItem;

            const signInStartTimeBeforeTenMinute = new Date(nowClassScheduleItem.startTime);
            signInStartTimeBeforeTenMinute.setMinutes(signInStartTimeBeforeTenMinute.getMinutes() - 10);

            if(nowTime.getTime() < signInStartTimeBeforeTenMinute.getTime()) {
                this.nowState = 1;
            }
            else if(nowTime.getTime() >= signInStartTimeBeforeTenMinute.getTime() && nowTime.getTime() < nowClassScheduleItem.startTime.getTime()) {
                this.nowState = 2;
            }
            else if(nowTime.getTime() >= nowClassScheduleItem.startTime.getTime()) {
                this.nowState = 3;
            }

            this.nowStateText = this.getNowStateText();
        },

        init() {
            const _this = this;

            this.nowState = 0;
            this.nowStateText = this.getNowStateText();

            user.userIsLogin(
                () => {
                    Promise.all([this.initData(), this.initOpencv()])
                    .then(() => {
                        _this.dataProcessing();
                        _this.selectGymsAndClassShow = true;
                    })
                    .catch((res) => {
                        interaction.showErrorMessage(res);
                    })
                }
            );
        },

        initData() {
            const _this = this;
            return new Promise((resolve, reject) => {
                gyms.selectGymsAllAndClassAllByUserId(
                    (res) => {
                        _this.gyms = res.gyms;
                        _this.gymsClass = res.gymsClass;
                        resolve();
                    },
                    (res) => {
                        reject(res);
                    }
                );
            })
        },

        initOpencv() {
            return new Promise((resolve, reject) => {
                faceDetection.init(
                    () => {
                        resolve();
                    }
                );
            })
        },

        dataProcessing() {
            let gymsSelectorList = [];
            let gymsClassJson = {};

            for(let item of this.gyms) {
                gymsSelectorList.push({
                    label: item.gymsName,
                    value: item.gymsId,
                    children: [],
                })
                gymsClassJson[item.gymsId] = [];
            }
            

            for(let item of this.gymsClass) {
                gymsClassJson[item.gymsId].push({
                    label: item.name,
                    value: [item.gymsId, item.classId],
                })
            }

            for(let item of gymsSelectorList) {
                item.children = gymsClassJson[item.value];
            }

            this.gymsSelectorList = gymsSelectorList;
        },

        refreshClassScheduleList() {
            gyms_classSchedule.selectGymsClassScheduleByGymsIdAndClassId(
                this.gymsId, this.classId,
                (res) => {
                    let classScheduleList = res.classScheduleList;
                    let newClassScheduleList = [];

                    if(classScheduleList == undefined) {
                        this.classScheduleList = [];
                        return;
                    }

                    let currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);
                    let nextDate = new Date(currentDate);
                    nextDate.setDate(nextDate.getDate() + 1);

                    for(let item of classScheduleList) {
                        if(item.startTime >= currentDate.getTime() && item.startTime < nextDate.getTime()) {
                            newClassScheduleList.push({
                                startTime: new Date(item.startTime),
                                endTime: new Date(item.endTime),
                                startTimeText: dataJudgment.formatDateTime(item.startTime),
                                endTimeText: dataJudgment.formatDateTime(item.endTime),
                                timeText: `${dataJudgment.formatTime(item.startTime)} ~ ${dataJudgment.formatTime(item.endTime)}`,
                                signInBeforeStudentList: item.signInBeforeStudentList,
                            })
                        }
                    }

                    newClassScheduleList.sort((left, right) => {
                        return left.startTime.getTime() - right.startTime.getTime();
                    })

                    this.classScheduleList = newClassScheduleList;
                },
                (res) => {
                    interaction.showErrorMessage(res);
                    this.selectGymsAndClassShow = true;
                }
            );
        },

        begin() {
            this.refreshClassScheduleList();
            this.openCamera();
        },

        changeSelectorData(data) {
            if(data.length != 2) {
                interaction.showErrorMessage("该机构无班级，不可选择");
                return;
            }
            this.gymsId = data[0];
            this.classId = data[1];
            this.selectorShowText = data;
        },

        //选择机构和班级后才开始加载
        isChangeSelectorData() {
            if(this.gymsId == 0 || this.classId == 0) {
                interaction.showErrorMessage("机构/班级未选择！")
                return;
            }
            this.selectGymsAndClassShow = false;
            this.begin();
            
        },

        openCamera () {
            navigator.mediaDevices.getUserMedia({
                video: true
            }).then(success => {
                this.$refs['video'].srcObject = success
                this.$refs['video'].play()
                this.cameraDataProcessing();
            }).catch(error => {
                console.log(error);
                console.error('摄像头开启失败，请检查摄像头是否可用！')
            })
        },

        closeCamera () {
            if (!this.$refs['video'].srcObject) {
                this.dialogCamera = false
                return
            }
            let stream = this.$refs['video'].srcObject
            let tracks = stream.getTracks()
            tracks.forEach(track => {
                track.stop()
            })
            this.$refs['video'].srcObject = null
        },

        cameraDataProcessing() {
            const _this = this;
            const video = document.getElementById('video');
            const canvas = document.getElementById('canvasOutput');
            const ctx = canvas.getContext('2d');
            
            const FPS = 1;

            function processVideo() {
                _this.imageDataProcessing(
                    video, canvas, ctx,
                    () => {
                        let timeoutObject = setTimeout(() => {
                            processVideo();
                            clearTimeout(timeoutObject);
                        }, 1000 / FPS);
                    }
                );
                
            }

            // 开始处理视频
            processVideo();
        },

        imageDataProcessing(video, canvas, ctx, callback) {
            if (video.readyState != video.HAVE_ENOUGH_DATA) {
                callback();
                return;
            }

            //非签到状态
            if(this.nowState != 2 && this.nowState != 3) {
                return;
            }

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            let src = cv.imread(canvas);

            const faceCascade = faceDetection.GlobalSData.faceCascade;
            let faces = new cv.RectVector();
            faceCascade.detectMultiScale(src, faces, 1.1, 3, 0);

            let maxFaceItem = undefined;
            let maxFaceSize = 0;
            for (let i = 0; i < faces.size(); ++i) {
                let item = faces.get(i);
                if(item.width * item.height > maxFaceSize) {
                    maxFaceItem = item;
                    maxFaceSize = item.width * item.height;
                }
            }

            if(maxFaceItem === undefined) {
                callback();
                return;
            }

            cv.rectangle(
                src, 
                new cv.Point(maxFaceItem.x, maxFaceItem.y), 
                new cv.Point(maxFaceItem.x + maxFaceItem.width, maxFaceItem.y + maxFaceItem.height), 
                [255, 0, 0, 255]
            );

            cv.imshow("canvasOutput", src);
            src.delete();
            faces.delete();

            const fileByBase64 = canvas.toDataURL('image/png').split(',')[1];
            faceRecognition.faceBase64SearchToStudentIdByOneToMany(
                this.gymsId, this.classId, fileByBase64,
                (res) => {
                    if(res != undefined) {
                        this.userSignIn(res, callback);
                    }
                },
                (res) => {
                    callback();
                }
            );
        },

        userSignIn(data, callback) {
            var information = data.studentInformation;
            
            if(information == undefined) {
                callback();
                return;
            }

            if(!this.userSignInStateCheck()) {
                interaction.showErrorMessage("当前不在可签到时间");
                callback();
                return;
            }

            const studentId = data.studentId;
            const name = information.name;

            this.identifiedUrl = fileImage.getStudentImagePathNeedRefersh(studentId, name);
            this.identifiedName = name;

            for(let item of this.nowClassScheduleItem.signInBeforeStudentList) {
                if(item.studentId == studentId) {
                    interaction.showSuccessMessage("签到成功！");
                    callback();
                    return;
                }
            }

            const nowTime = new Date();
            const startTimeAfterTenMinute = new Date(this.nowClassScheduleItem.startTime);
            startTimeAfterTenMinute.setMinutes(startTimeAfterTenMinute.getMinutes() + 10);

            let state = gymsData.gymsClassStudentSignInClassStatus.Normal;
            if(nowTime >= startTimeAfterTenMinute.getTime()) {
                state = gymsData.gymsClassStudentSignInClassStatus.Late;
            }

            gyms_classSchedule.appendGymsClassScheduleSignInBeforeStudent(
                this.gymsId, this.classId, 
                dataJudgment.formatDateTime(this.nowClassScheduleItem.startTime, "-"), dataJudgment.formatDateTime(this.nowClassScheduleItem.endTime, "-"),
                studentId, state,
                (res) => {
                    interaction.showSuccessMessage("签到成功！");

                    let identifiedTimeoutObject = setTimeout(() => {
                        if(this.identifiedTimeoutObject == identifiedTimeoutObject) {
                            this.identifiedUrl = "";
                            this.identifiedName = "";
                        }
                        clearTimeout(identifiedTimeoutObject);
                    }, 2000);
                    this.identifiedTimeoutObject = identifiedTimeoutObject;

                    callback();
                },
                (res) => {
                    interaction.showErrorMessage(res);
                    callback();
                }
            );
        },
    },
    mounted() {
        this.changeShowHeader(false);
        this.resizeScreen();
        this.init();

        window.addEventListener('resize',this.resizeScreen);
        this.nowTimeTimer = setInterval(()=> {
            this.nowTimeText = dataJudgment.formatDateTime(new Date());
        }, 500)
        this.nowStateTimer = setInterval(this.refreshClassScheduleAndNowState, 5000)
    },
    unmounted() {
        this.changeShowHeader(true);
        window.removeEventListener('resize',this.resizeScreen);
        clearInterval(this.nowTimeTimer);
        clearInterval(this.nowStateTimer);
    }
}
</script>

<style src="@/css/view.css" scoped></style>
<style src="@/css/information.css" scoped></style>
<style scoped>

.fixAll {
    width: 100%;
    height: 100%;
}

.imageMode {
    object-fit: cover;
}

.background {
    margin: 0;
    padding: 5%;
    box-sizing: border-box;
    height: v-bind(height);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: linear-gradient(to right, rgb(255, 239, 186), rgb(255, 255, 255));
}

.leftBackground {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.faceRecognitionBackground {
    overflow: hidden;
    border-radius: 20px;
}
.identifiedBackground {
    display: flex;
    flex-direction: row;
}
.identifiedImageItem {
    height: 100%;
    width: auto;
    aspect-ratio: 2 / 3;
    background: var(--el-fill-color-light);
    object-fit: cover;
    border-radius: 10px;
}
.identifiedTextBackground {
    display: flex;
    flex: auto;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    margin-left: 10%;
}

.rightBackground {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    font-size: 18px;
}

</style>