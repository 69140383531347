<template>
    <header>
        <el-menu v-if="showHeader" class="el-menu-demo" mode="horizontal" :ellipsis="false" :router="true">
            <el-menu-item index="/">曦禾考级系统</el-menu-item>
            <div style="flex-grow: 1;"></div>
            <el-menu-item index="/matches/matches">
                <el-icon><Flag /></el-icon>
                <span>考级中心</span>
            </el-menu-item>
            <el-sub-menu index="matchesRatio">
                    <template #title>
                        <el-icon><Setting /></el-icon>
                        <span>考核项管理</span>
                    </template>
                    <!-- <el-menu-item index="/matchesRatio/create">考核项模板创建</el-menu-item> -->
                    <el-menu-item index="/matchesRatio/list">考核项模板管理</el-menu-item>
                </el-sub-menu>
            <el-sub-menu index="matchesControl">
                <template #title>
                    <el-icon><Setting /></el-icon>
                    <span>考级管理</span>
                </template>
                <!-- <el-menu-item index="/matchesControl/create">考级创建</el-menu-item> -->
                <el-menu-item index="/matchesControl/list">考级管理</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="user">
                <template #title>
                    <el-icon><User /></el-icon>
                    <span>用户中心</span>
                </template>
                <el-menu-item index="/user/userLogin">用户登录</el-menu-item>
                <!-- <el-menu-item index="/user/userRegistry">用户注册</el-menu-item> -->
            </el-sub-menu>
        </el-menu>
    </header>
</template>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    export default {
        name: 'HeaderView',
        props: {
            msg: String
        },
        setup() {
            const store = useStore();
            const showHeader = computed(() => store.state.showHeader);

            return {
                showHeader
            }
        },
        methods: {

        },
        mounted() {

        }
    }
</script>

<style scoped>
</style>